.matchmaking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0e0e0e;
}

.status-message {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.status-message .spinner {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border: 4px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.status-message h2 {
  font-size: 24px;
  color: #fff;
}

.invite-link-container {
  text-align: center;
  color: #fff;
}

.invite-link {
  display: flex;
  align-items: center;
  background-color: #23272f;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.invite-link pre {
  margin: 0;
  color: #fff;
  overflow-x: auto;
}

.invite-link button {
  margin-left: 10px;
  background-color: #2c313a;
  border: none;
  color: #fff;
  padding: 6px 12px;
  border-radius: 3px;
  cursor: pointer;
}

.invite-link button:hover {
  background-color: #3a3f4b;
}

.error-message {
  color: #d8000c;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.error-message button,
.join-game-container button {
  background: #23272f;
  border: 2px solid #ffffff1a;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.error-message h2 {
  margin-top: 0;
  color: #d8000c;
}

.error-message button {
  margin: 10px;
}

.join-game-container {
  text-align: center;
  color: #fff;
}

.join-game-container h2 {
  margin-bottom: 20px;
  color: #fff;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
