.playground-container {
  position: relative;
  min-width: 1040px;
  width: 100%;
  height: 100vh;
}

.end-turn-button {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  font-size: 18px;
  background-color: #23272f;
  color: white;
  border: none;
  cursor: pointer;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: 3px solid #ffffff1a;
  width: 300px;
}

.end-turn-button:hover {
  background-color: #0056b3;
}

.balance-container {
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #23272f;
  border: 3px solid #ffffff1a;
  color: #fff;
  font-weight: bold;
  padding: 10px;
  padding-top: 20px;
  min-width: 220px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.balance-label,
.ap-label,
.balance-value {
  display: inline-block;
}

.ap-title {
  position: absolute;
  top: 5px;
  left: 10px;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
}

.balance-label {
  padding-left: 5px;
  border-left: 2px dashed #828282;
}

.balance-label img {
  width: 32px;
}

.balance-value {
  font-size: 24px;
  padding-left: 5px;
}

.ap-label img {
  width: 64px;
  margin-right: 10px;
}

.ap-label.empty img {
  opacity: 0.2;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: #fff;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00bcd4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #0097a7;
}

.help-button {
  position: fixed;
  top: 20px;
  right: 80px;
  background-color: #23272f;
  border: 2px solid #393d44;
  color: #fff;
  font-size: 18px;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 100;
}

.help-button:hover {
  background-color: #2c313a;
}

.help-button:focus {
  outline: none;
}

.sound-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #23272f;
  border: 2px solid #393d44;
  color: #fff;
  font-size: 18px;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 100;
}

.sound-toggle-button:hover {
  background-color: #2c313a;
}

.sound-toggle-button:focus {
  outline: none;
}

.spinner {
  margin-right: 8px;
  border: 2px solid #fff;
  border-top: 2px solid #00bcd4;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 0.8s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .end-turn-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    top: 0;
    bottom: unset;
  }
}
